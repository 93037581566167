.bsnav-mobile{
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: fixed;
	z-index: 49;
	pointer-events: none;


	.bsnav-mobile-overlay{
		// background: rgba(#000,.3);
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: fixed;
		visibility: hidden;
		pointer-events: none;
	}//bsnav-mobile-overlay


	.navbar{
		background: #fff;
		width: 230px;
		padding: 70px 0 15px;
		flex-flow: column;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		transition: .4s ease-in-out;
		transform: translate3d(300px,0,0);
		overflow: auto;



		&.bsnav-dark{ background: #000; }

	}//navbar


	@include navbar-nav;







	&.in{
		pointer-events: initial;

		.navbar{
			transform: translate3d(0,0,0);
		}//navbar

		.bsnav-mobile-overlay{
			visibility: visible;
			pointer-events: initial;
		}//bsnav-mobile-overlay

	}//in

}//bsnav-mobile